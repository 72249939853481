@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url("../fonts/Inter.woff2") format("woff2");
}

@font-face {
  font-family: "Medium";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MediumRegular.woff") format("woff"), url("../fonts/MediumRegular.woff2") format("woff2");
}

